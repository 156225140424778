@import '../../Styles/varsSnowmen.scss';
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap');

.Snowmen {
	font-size: 62.5%;

	@media screen and (max-width: $large_desktop) {
		font-size: 100vw / (1480/10);
	}

	@media screen and (max-width: $large_tablet) {
		font-size: 80.5%;
	}

	@media screen and (max-width: $large_mobile) {
		font-size: 100vw / (480 /10);
	}

	body {
		background-color: #ffffff;
		font-size: 1.6rem;
		font-family: 'Open Sans', sans-serif;
		&::-webkit-scrollbar {
			width: 0;
		}
	}

	.center-wrap {
		width: 100%;
		margin: 0px auto;
		padding: 0px 15px;

		@media screen and (max-width: $large_desktop) {
			max-width: calc(100% - 50px);
		}

		@media screen and (max-width: $large_tablet) {
			max-width: $large_mobile;
			padding: 0 3.2rem;
		}
	}

	.simple-title {
		font-family: 'Dancing Script';
		font-weight: 400;
		font-size: 9.6rem;
		line-height: 11.5rem;

		@media screen and (max-width: $large_tablet) {
			font-size: 4.8rem;
			line-height: 5.8rem;
		}
	}

	.desktop--hide {
		@media screen and (min-width: $large_tablet) {
			display: none !important;
		}
	}

	.mobile--hide {
		@media screen and (max-width: $large_tablet) {
			display: none !important;
		}
	}
}
