@import '../../Styles/varsCupids.scss';

.Cupids {
	.mobile-menu {
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 0px;
		bottom: 0px;
		z-index: 102;
		overflow: hidden;
		display: none;
		transition: all 0.5s;
		opacity: 0;
		touch-action: none;

		&__bg {
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(#000000, 0.75);
			top: 0;
			left: 0;
			cursor: pointer;
		}

		&--active {
			display: flex;
		}

		&--opacity0 {
			opacity: 0;
		}

		&--opacity1 {
			opacity: 1;
		}

		&__wrap {
			background-color: #fff;
			padding: 4.25rem 4.25rem 4.25rem 7.5rem;
			width: 480px;
			height: 100%;
			position: absolute;
			left: -480px;
			transition: left 0.5s;
			display: flex;
			flex-direction: column;

			@media screen and (max-width: $large_mobile) {
				width: 100%;
				left: -100%;
			}

			&--left0 {
				left: 0px;
			}

			&--left300 {
				left: -480px;

				@media screen and (max-width: $large_mobile) {
					left: -100%;
				}
			}
		}

		&__top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 7rem;
		}

		&__logo {
			max-width: 19.5rem;

			> div {
				padding-bottom: 28.2%;

				img {
					object-fit: contain;
				}
			}
		}

		&__act {
			width: 3.5rem;
			height: 3.5rem;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			svg {
				width: 100%;
				height: 100%;
			}
		}

		&__nav {
			position: relative;

			&-list {
				display: flex;
				flex-direction: column;
				list-style: none;
			}

			&-item {
				margin-bottom: 6.4rem;

				&:last-child {
					margin-bottom: 0;
				}
			}

			&-link {
				font-size: 3.6rem;
				position: relative;
				z-index: 5;
				color: #dc7081;

				&::before {
					content: '';
					position: absolute;
					left: 0;
					bottom: 0px;
					width: 100%;
					height: 3px;
					background: #dc7081;
					opacity: 0;
					transition: 0.5s;
				}

				&:hover {
					&::before {
						opacity: 1;
					}
				}
			}
		}
	}
}
