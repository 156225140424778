@import '../../Styles/vars.scss';

.SadBear {
	.team {
		position: relative;
		padding: 19rem 0 37.7rem;

		@media screen and (max-width: $large_tablet) {
			padding: 9rem 0rem 8rem;
		}

		.center-wrap {
			@media screen and (max-width: $large_tablet) {
				padding: 0;
				max-width: 100%;
			}
		}

		&__bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: -1;

			@media screen and (max-width: $large_tablet) {
				height: 100%;
			}

			&-white {
				left: 0;
				width: 100%;
				position: absolute;
				bottom: -10rem;
				z-index: 1;

				@media screen and (max-width: $large_tablet) {
					bottom: 0;
					height: 100%;
				}
			}
		}

		&__title {
			text-transform: uppercase;
			margin-bottom: 9.6rem;

			@media screen and (max-width: $large_tablet) {
				margin-bottom: 3.4rem;
			}
		}

		&__wrap {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		&__member {
			margin-bottom: 2.4rem;
			width: calc((100% - 2rem) / 2);
			padding: 4.8rem 3.7rem 4.8rem 6rem;
			background: rgba(255, 255, 255, 0.4);
			border-radius: 2.4rem;

			@media screen and (max-width: $large_tablet) {
				width: 100%;
				padding: 4.8rem 4.05rem;
				margin: 0 auto 3.4rem;
				max-width: 36.4rem;
			}

			&-wrap {
				display: flex;
				align-items: center;
				margin-bottom: 2.4rem;

				@media screen and (max-width: $large_tablet) {
					flex-direction: column;
					text-align: center;
				}
			}

			&-img {
				margin-right: 4rem;
				object-fit: contain;
				width: 100%;
				max-width: 16.4rem;

				@media screen and (max-width: $large_tablet) {
					margin-right: 0rem;
					margin-bottom: 4rem;
				}
			}

			&-name {
				font-family: 'Laila', sans-serif;
				font-size: 4.8rem;
				line-height: 7.2rem;
				color: #1d4449;
				margin-bottom: 1.2rem;
				font-weight: bold;

				@media screen and (max-width: $large_tablet) {
					line-height: 5.5rem;
					margin-bottom: 2.5rem;
				}
			}

			&-role {
				font-family: 'Laila', sans-serif;
				font-size: 4rem;
				line-height: 4.8rem;
				color: #3eac94;
				font-weight: bold;
			}

			&-description {
				font-size: 2.4rem;
				line-height: 3.6rem;
				color: #121212;
			}
		}
	}
}
