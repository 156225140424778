@import '../../Styles/varsSnowmen.scss';

.Snowmen {
	.preloader {
		width: 100%;
		position: fixed;
		height: 100%;
		background-color: #e1f1fe;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9999;
		transition: opacity 0.5s;
		animation-fill-mode: forwards !important;
		overflow: hidden;
		line-height: 1.6;
		font-weight: 500;
		font-size: 2.5rem;
		top: 0;

		&-wrap {
			position: relative;
		}

		&--none {
			display: none;
		}

		&-line {
			position: absolute;
			bottom: -7rem;
			max-width: 40rem;
			width: 100%;
			height: 2px;
			overflow: hidden;

			@media screen and (max-width: $large_tablet) {
				max-width: 24rem;
			}

			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 0;
				width: 100%;
				height: 2px;
				transform: translate(-100%, 0);
			}

			&:before {
				background-color: rgba(0, 0, 0, 0.2);
				animation: loading 3.5s 0.5s cubic-bezier(0.23, 1, 0.32, 1);
			}

			&:after {
				background-color: #000000;
				animation: loading 2.5s 1s cubic-bezier(0.23, 1, 0.32, 1);
			}

			@keyframes loading {
				75% {
					transform: translate(0, 0);
				}

				100% {
					transform: translate(100%, 0);
				}
			}
		}

		&-logo {
			width: 40rem;
			position: relative;
			animation: preloaderLogoOpacity 1s 0.3s ease-in-out forwards;
			opacity: 0;

			@media screen and (max-width: $large_tablet) {
				width: 24rem;
			}

			@keyframes preloaderLogoOpacity {
				0% {
					opacity: 0;
				}

				100% {
					opacity: 1;
				}
			}

			img {
				width: 100%;
				animation: preloaderAnim 3.2s 0.3s ease-in-out;
				animation-fill-mode: forwards;

				@keyframes preloaderAnim {
					0% {
						transform: scale(1);
					}

					100% {
						transform: scale(1.1);
					}
				}
			}
		}

		&.preloader--hide {
			transition: 1s;
			opacity: 0;
		}
	}
}
