@import '../../Styles/vars.scss';

.SadBear {
	.connect {
		width: 100%;
		padding-top: 7.6rem;
		background-image: url('../../Images/connect-bg.png');
		background-repeat: no-repeat;
		background-size: cover;

		@media screen and (max-width: $large_tablet) {
			padding-top: 9.6rem;
		}

		&__img {
			max-width: 69.2rem;
			width: 100%;
			margin-left: -3rem;
		}

		&__info {
			@media screen and (max-width: $large_tablet) {
				margin-bottom: 3.4rem;
			}
		}

		&__wrap {
			display: flex;
			align-items: center;
			position: relative;
			width: 100%;

			@media screen and (max-width: $large_tablet) {
				flex-direction: column-reverse;
			}

			.connect__title {
				text-align: left;
				line-height: 9.6rem;
				color: #000000;

				@media screen and (max-width: $large_tablet) {
					font-size: 4rem;
					line-height: 7.2rem;
					text-align: center;
				}
			}
		}

		&__title {
			margin-bottom: 5.2rem;
			max-width: 61.3rem;
			width: 100%;

			@media screen and (max-width: $large_tablet) {
				margin-bottom: 2.4rem;
				max-width: 33.3rem;
			}
		}

		&__links {
			display: flex;

			@media screen and (max-width: $large_tablet) {
				flex-direction: column;
			}

			a {
				background: #184066;
				border-radius: 4.3rem;
				display: flex;
				align-items: center;
				padding: 1.2rem 2.6rem;
				margin-right: 2rem;
				opacity: 0.85;
				cursor: pointer;
				transition: 0.5s;

				@media screen and (max-width: $large_tablet) {
					margin-bottom: 2.4rem;
					margin-right: 0;

					&:last-of-type {
						margin-bottom: 0;
					}
				}

				img {
					width: 5.6rem;
					height: 5.6rem;
				}

				&:hover {
					opacity: 0.75;
				}

				&:last-of-type {
					margin-right: 0;
				}

				p {
					font-family: 'Laila', sans-serif;
					font-weight: bold;
					font-size: 2.4rem;
					line-height: 3.6rem;
					color: #ffffff;
					margin-left: 1.6rem;
				}
			}
		}
	}
}
