@import '../../Styles/varsCupids.scss';

.Cupids {
	.roadmap {
		padding: 38.5rem 44.9rem 14.8rem 0;
		position: relative;
		z-index: 2;

		@media screen and (max-width: $large_desktop) {
			padding: 38.5rem 10rem 14.8rem 0;
		}

		@media screen and (max-width: $large_tablet) {
			padding: 9.2rem 0 17.9rem;
		}

		&::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 10rem;
			top: 0;
			left: 0;
			background: rgb(255, 255, 255);
			background: linear-gradient(
				180deg,
				rgba(255, 255, 255, 1) 0%,
				rgba(255, 255, 255, 0) 100%
			);
			z-index: 2;
		}

		&::before {
			content: '';
			position: absolute;
			top: 6.6rem;
			left: 0;
			height: calc(100% - 6.6rem);
			width: 100%;
			background-size: cover;
			background-position-y: top;
			background-repeat: no-repeat;
			background-image: url('../../Images/roadmap-bg.png');
			z-index: -1;

			@media screen and (max-width: $large_tablet) {
				height: 100%;
				top: -1rem;
				background-image: url('../../Images/roadmap-mobile-bg.png');
			}
		}

		&__simple-title {
			color: #ad4656;
			margin-bottom: 4.4rem;

			@media screen and (max-width: $large_tablet) {
				margin-bottom: 2.4rem;
			}
		}

		&__wrap {
			@media screen and (max-width: $large_tablet) {
				padding-left: 11rem;
			}
		}

		&__right {
			max-width: 45.3rem;
			width: 100%;
			margin-left: auto;

			@media screen and (max-width: $large_tablet) {
				max-width: 100%;
			}
		}

		&__list {
			position: relative;
		}

		&__content {
			max-width: 45.3rem;

			p {
				display: inline-block;
			}
		}

		&__item {
			position: relative;
			padding-bottom: 5.1rem;

			&::after {
				content: '';
				position: absolute;
				width: 2px;
				background: #c4c4c4;
				top: 4.7rem;
				left: -6.3rem;
				height: calc(100% - 4rem);
			}

			&::before {
				content: '';
				position: absolute;
				left: -7.8rem;
				top: 1.5rem;
				background-size: contain;
				background-repeat: no-repeat;
				background-image: url('../../Images/heart.svg');
				width: 3.2rem;
				height: 3.2rem;
			}

			&:last-of-type {
				padding-bottom: 0;

				&::after {
					display: none;
				}
			}
		}

		&__number {
			color: #000000;
			font-weight: bold;
			font-size: 4.8rem;
			line-height: 6.5rem;
			letter-spacing: 0.08em;
			margin-bottom: 1.2rem;
		}
	}
}
