@import '../../Styles/vars.scss';

.SadBear {
	.product {
		padding-top: 19rem;
		padding-bottom: 160rem;

		@media screen and (max-width: $large_tablet) {
			padding-top: 5.3rem;
			padding-bottom: 180rem;
		}

		&__title {
			font-size: 6.4rem;
			line-height: 100%;
			text-transform: uppercase;
			text-align: center;
			color: $secondary-c;
			margin-bottom: 6.5rem;
			font-family: 'Hiruko';
			font-weight: 400;

			@media screen and (max-width: $large_tablet) {
				font-size: 4.8rem;
				text-align: left;
				margin-bottom: 6rem;
				line-height: 1.12;
			}
		}

		&__top {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-bottom: 5rem;
			border-bottom: 1px solid #f1eff7;
			font-family: $primary-f;

			@media screen and (max-width: $large_tablet) {
				flex-wrap: wrap;
				justify-content: left;
				padding-bottom: 2.5rem;
			}

			.toogles__item-title--active {
				color: #13d6da;
			}

			&-item {
				font-weight: 500;
				font-size: 2.4rem;
				line-height: 2.6rem;
				// color: $primary-c;
				transition: color 0.5s;
				cursor: pointer;
				position: relative;
				padding-right: 2.8rem;

				@media screen and (max-width: $large_tablet) {
					margin-bottom: 2.6rem;
				}

				& + .product__top-item {
					margin-left: 2.8rem;
				}

				&:last-child {
					padding-right: 0;

					&::before {
						display: none;
					}
				}

				&:nth-child(3n + 3) {
					@media screen and (max-width: $large_tablet) {
						padding-right: 0;

						&::before {
							display: none;
						}
					}
				}
				&:nth-child(4) {
					@media screen and (max-width: $large_tablet) {
						margin-left: 0;
					}
				}

				&:hover {
					color: $secondary-c;
				}

				&::before {
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					width: 1px;
					height: 100%;
					background: #7798b3;
				}
			}
		}

		&__body {
			max-width: 110rem;
			width: 100%;
			margin: 0 auto;

			&-title {
				font-size: 6.4rem;
				line-height: 6.4rem;
				text-align: center;
				margin-bottom: 6.4rem;
				padding-top: 4rem;
				font-family: 'Hiruko';
				font-weight: 400;

				@media screen and (max-width: $large_tablet) {
					margin-bottom: 6.4rem;
					font-size: 4.8rem;
					line-height: 4.8rem;
				}
			}

			&-subtitle {
				font-size: 4.8rem;
				line-height: 4.8rem;
				margin-bottom: 4.8rem;
				text-align: center;
				font-family: 'Hiruko';
				font-weight: 400;

				@media screen and (max-width: $large_tablet) {
					margin-bottom: 3rem;
					font-size: 3.2rem;
					line-height: 3.2rem;
				}
			}

			&-scroll {
				overflow: auto;

				@media screen and (max-width: $large_tablet) {
					max-width: 25.5rem;
					margin: 0 auto;
				}

				&::-webkit-scrollbar-thumb {
					background: #00ffff;
					border-radius: 16px;
				}

				&::-webkit-scrollbar {
					height: 1rem;
				}
			}

			&-list {
				display: flex;
				width: 100%;
				padding-bottom: 2.4rem;
			}

			&-box {
				margin-bottom: 6.4rem;

				@media screen and (max-width: $large_tablet) {
					margin-bottom: 5rem;
				}

				&:last-child {
					margin-bottom: 0px;
				}
			}
		}

		&__item {
			min-width: calc((100% / 4) - 3rem);
			margin-right: 3rem;
			padding: 4rem 1rem 6.4rem;
			background: rgba(196, 196, 196, 0.35);
			mix-blend-mode: overlay;
			border-radius: 5rem;

			@media screen and (max-width: $large_tablet) {
				min-width: 100%;
				width: 100%;
				margin-right: 0;
			}

			&:last-child {
				margin-right: 0px;
			}

			&-title {
				font-weight: normal;
				font-size: 3.6rem;
				line-height: 4rem;
				margin-bottom: 3rem;
				text-align: center;
			}

			&-img {
				max-width: 14rem;
				margin: 0 auto;

				> div {
					padding-bottom: 100%;
				}
			}
		}
	}
}
