// Font
$primary-f: 'Open Sans', sans-serif;

// Color
$primary-c: #fff;
$secondary-c: #004570;
$danger-c: #ff0000;

// Media
$large_desktop: 1480px;
$large_tablet: 1024px;
$large_mobile: 600px;
