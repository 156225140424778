@import '../../Styles/vars.scss';

.SadBear {
	.main-screen {
		position: relative;
		width: 100%;
		background-image: url('../../Images/main-screen-bg.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top center;
		z-index: -1;

		@media screen and (max-width: $large_tablet) {
			background-position: 80% -15rem;
		}

		&__wrap {
			padding: 0rem 2rem 0rem 12rem;
			min-height: 102rem;
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media screen and (max-width: $large_tablet) {
				min-height: auto;
				padding: 19.8rem 0 7.7rem;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
			}
		}

		&__title {
			font-family: 'Laila', sans-serif;
			font-weight: bold;
			font-size: 9.6rem;
			line-height: 12.8rem;
			letter-spacing: 1px;
			color: #1d4449;

			@media screen and (max-width: $large_tablet) {
				font-size: 6.4rem;
				line-height: 9.6rem;
			}
		}

		&__description {
			font-family: 'Laila', sans-serif;
			color: #1d4449;
			font-size: 4rem;
			line-height: 6.4rem;

			@media screen and (max-width: $large_tablet) {
				font-size: 2.4rem;
				line-height: 3.6rem;
			}
		}

		&__image {
			max-width: 67.1rem;
			width: 100%;
			margin-top: auto;
			position: relative;

			@media screen and (max-width: $large_tablet) {
				margin-top: 8rem;
			}

			&::before {
				content: '';
				position: absolute;
				z-index: 1;
				bottom: 0;
				left: 0;
				background: linear-gradient(
					0deg,
					#ffffff 0%,
					rgba(255, 255, 255, 0) 100%
				);
				width: 100%;
				height: 16%;
			}

			& > div {
				padding-bottom: 116.54%;
			}
		}

		&__bottom {
			padding: 0rem 0 7rem;
			z-index: 2;
			position: relative;

			@media screen and (max-width: $large_tablet) {
				padding: 0;
			}

			&-text {
				display: flex;
				justify-content: space-between;
				padding-left: 12rem;

				@media screen and (max-width: $large_tablet) {
					flex-direction: column;
					padding-left: 0;
				}

				& > div {
					width: 50%;

					@media screen and (max-width: $large_tablet) {
						width: 100%;
					}
				}

				& > div:first-of-type {
					@media screen and (max-width: $large_tablet) {
						margin-bottom: 2.4rem;
					}
				}

				p {
					display: block;
					max-width: 57rem;
					width: 100%;
					font-size: 2.4rem;
					line-height: 3.6rem;
					margin-bottom: 3.6rem;
					color: #121212;
					white-space: pre-wrap;

					@media screen and (max-width: $large_tablet) {
						font-size: 1.6rem;
						line-height: 2.4rem;
						margin-bottom: 2.4rem;
					}

					.line {
						display: block;
					}

					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
