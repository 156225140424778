// Font
$primary-f: 'Graphik', sans-serif;

// Color
$primary-c: #fff;
$secondary-c: #ff9460;
$danger-c: #ff0000;

$wrap: 1430px;
// Media
$large_desktop: 1480px;
$large_tablet: 1024px;
$large_mobile: 600px;
