* {
	box-sizing: border-box;
	margin: 0px;
	padding: 0px;
	outline: none;
}

img {
	max-width: 100%;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input {
	-webkit-border-radius: 0px;
	-webkit-appearance: none;
}

ul {
	list-style: none;
}

a {
	text-decoration: none;
	color: inherit;
}

body {
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
	font-size: 1.6rem;
	user-select: none;
}

.center-wrap {
	margin: 0 auto;
}

main {
	flex-grow: 1;
}

h1,
h2,
h3,
h4 {
	margin: 0px;
}

.img-wrap {
	width: 100%;

	> div {
		position: relative;
		padding-bottom: 100%;

		img {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
