@import '../../Styles/vars.scss';

.SadBear {
	.product-page {
		background-image: url('../../Images/product-bg.png');
		height: 100%;
		background-position: top center;
		background-repeat: no-repeat;
		background-size: cover;

		.header {
			&__container {
				@media screen and (max-width: $large_tablet) {
					max-width: $large_mobile;
					margin: 0 auto;
					padding: 0px 15px;
				}
			}

			&__logo-image {
				@media screen and (max-width: $large_tablet) {
					left: 0;
				}
			}
		}
	}
}
