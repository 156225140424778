@import '../../Styles/varsCupids.scss';

.Cupids {
	.rarity {
		padding: 32.4rem 0 48.8rem 37.8rem;
		position: relative;
		z-index: 3;

		@media screen and (max-width: $large_desktop) {
			padding: 32.4rem 0 48.8rem 20rem;
		}

		@media screen and (max-width: $large_tablet) {
			padding: 12rem 0 8.8rem;
			z-index: 2;
		}

		&::before {
			content: '';
			position: absolute;
			height: calc(100% - 5.2rem);
			width: 100%;
			top: 5.2rem;
			left: 0;
			background-size: cover;
			background-position-y: bottom;
			background-repeat: no-repeat;
			background-image: url('../../Images/rarity-bg.png');
			z-index: -1;

			@media screen and (max-width: $large_tablet) {
				height: 100%;
				top: 0;
				background-position-y: 35%;
				background-image: url('../../Images/rarity-mobile-bg.png');
			}
		}

		&__wrap {
			display: flex;
			width: 100%;

			@media screen and (max-width: $large_tablet) {
				flex-direction: column;
			}
		}

		&__left {
			max-width: 45.3rem;
			width: 100%;
		}

		&__right {
			max-width: 114.5rem;
			width: 59.64%;
			margin-left: 1.5rem;
			position: absolute;
			top: -5.8rem;
			right: 0;

			@media screen and (max-width: $large_desktop) {
				max-width: 90rem;
				top: 0;
			}

			@media screen and (max-width: $large_tablet) {
				position: static;
				margin-left: 0;
				width: 100%;
				margin-top: 1.6rem;
			}

			> div {
				padding-bottom: 98.34%;

				@media screen and (max-width: $large_tablet) {
					padding-bottom: 84.07%;

					img {
						object-fit: contain;
					}
				}
			}
		}

		&__title {
			margin-bottom: 3.6rem;
			color: #000000;

			@media screen and (max-width: $large_tablet) {
				margin-bottom: 1.6rem;
				text-align: center;
			}
		}
	}
}
