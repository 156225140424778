@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import '../../Styles/varsCupids.scss';

.Cupids {
	.chart {
		padding: 34.5rem 0 20rem;
		background-color: #ffffff;
		position: relative;
		z-index: 1;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 95rem;
			background-image: url('../../Images/main-screen-bg.png');
			background-repeat: no-repeat;
			background-size: cover;
			z-index: -1;

			@media screen and (max-width: $large_tablet) {
				background-position: bottom center;
				background-image: url('../../Images/main-screen-mobile-bg.png');
			}
		}

		@media screen and (max-width: $large_desktop) {
			padding-left: 10rem;
			padding-right: 10rem;
		}

		@media screen and (max-width: $large_tablet) {
			padding-left: 0rem;
			padding-right: 0rem;
		}

		.center-wrap {
			max-width: 123rem;

			@media screen and (max-width: $large_desktop) {
				max-width: calc(100% - 50px);
			}

			@media screen and (max-width: $large_tablet) {
				max-width: $large_mobile;
			}
		}

		&__top {
			position: relative;
			z-index: 2;
			max-width: 81.3rem;
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 2rem 0 7.3rem;
			margin-bottom: 8.7rem;

			@media screen and (max-width: $large_tablet) {
				max-width: 90%;
				padding-top: 1.5rem;
				margin-bottom: 6rem;
			}

			&-gradient {
				width: 100%;
				height: 4rem;
				background: linear-gradient(
					90deg,
					#fd8f48 0%,
					#7446ff 37.38%,
					#30adcc 72.1%,
					#2fa57c 102.54%
				);

				@media screen and (max-width: $large_tablet) {
					height: 3rem;
				}
			}

			&-item {
				position: absolute;
				top: 0rem;

				&:nth-of-type(2) {
					transform: translateX(-50%);
					left: 0;
				}
				&:nth-of-type(3) {
					left: 22.5rem;

					@media screen and (max-width: $large_tablet) {
						left: 25%;
					}
				}
				&:nth-of-type(4) {
					right: 26.3rem;

					@media screen and (max-width: $large_tablet) {
						right: 25%;
					}
				}
				&:nth-of-type(5) {
					transform: translateX(50%);
					right: 0;
				}

				& > div {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				span {
					display: block;
					width: 2px;
					height: 8.3rem;
					background: #0f0f0f;
					margin-bottom: 1.4rem;

					@media screen and (max-width: $large_tablet) {
						height: 6rem;
					}
				}

				p {
					font-size: 2.4rem;
					line-height: 3.2rem;
					color: #0f0f0f;

					@media screen and (max-width: $large_tablet) {
						font-size: 1.6rem;
						line-height: 2.6rem;
					}
				}
			}
		}

		&__item {
			max-width: 50rem;
			margin-bottom: 20rem;
			width: 100%;
			color: #181818;
			font-family: 'Roboto', sans-serif;

			&-top {
				display: flex;
				font-size: 2rem;
				line-height: 2.8rem;
				width: 100%;
				font-family: 'Graphik', sans-serif;
			}

			&-name {
				width: 25.9rem;

				@media screen and (max-width: $large_tablet) {
					width: 24rem;
				}
			}

			&-percent {
				width: 14.3rem;
				@media screen and (max-width: $large_tablet) {
					width: 8rem;
				}
			}

			&-rarity {
				width: 5.6rem;
				@media screen and (max-width: $large_tablet) {
				}
			}
		}

		&__main {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			@media screen and (max-width: $large_tablet) {
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
			}
		}

		&__title {
			font-size: 3.6rem;
			line-height: 3.2rem;
			color: #181818;
			margin-bottom: 4.8rem;
			font-family: 'Oleo Script', cursive;
		}

		&__list {
			padding-top: 1.6rem;
			padding-bottom: 2.4rem;
			border-bottom: 1px solid #8f95ab;

			&--mythic {
				.chart__list-item__number,
				.chart__list-item__rare {
					color: #fd8f48;
				}
			}

			&--common {
				.chart__list-item__number,
				.chart__list-item__rare {
					color: #2fa57c;
				}
			}

			&--uncommon {
				.chart__list-item__number,
				.chart__list-item__rare {
					color: #30adcc;
				}
			}

			&--rare {
				.chart__list-item__number,
				.chart__list-item__rare {
					color: #7446ff;
				}
			}

			&:first-of-type {
				padding-top: 0;
			}

			&:last-of-type {
				border-bottom: none;
				padding-bottom: 0;
			}

			&-item {
				display: flex;
				align-items: center;

				&__name {
					padding-right: 1.6rem;
					width: 17.9rem;

					@media screen and (max-width: $large_tablet) {
						width: 16rem;
					}
				}

				&__name,
				&__number,
				&__rare {
					font-size: 1.8rem;
					line-height: 3.2rem;

					@media screen and (max-width: $large_tablet) {
						font-size: 1.6rem;
						line-height: 2.8rem;
					}
				}

				&__number {
					width: 14.3rem;

					p {
						display: inline;
					}

					@media screen and (max-width: $large_tablet) {
						width: 8rem;
					}
				}

				&__rare {
					width: 10.2rem;
				}
			}

			&-item__img-box {
				max-width: 6.4rem;
				width: 100%;
				margin-right: 1.45rem;
				position: relative;

				.chart__list-item__img-hover {
					display: none;
					position: absolute;
					z-index: 2;
					top: calc(100% - (25rem / 1.5));
					width: 25rem;
					left: 5rem;
					padding: 3rem 1rem;
					background-color: #efd8d8;
				}

				&:hover {
					.chart__list-item__img-hover {
						display: block;
						animation: animHover 0.5s;
						@keyframes animHover {
							from {
								opacity: 0;
							}
							to {
								opacity: 1;
							}
						}
					}
				}
			}
			&-item__img {
				> div {
					img {
						object-position: center;
						object-fit: contain;
					}
				}

				@media screen and (max-width: $large_tablet) {
					max-width: 6rem;
					margin-right: 1rem;
				}
			}
		}
	}
}
