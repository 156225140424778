@import '../../Styles/vars.scss';

.SadBear {
	.mobile-menu {
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 0px;
		bottom: 0px;
		z-index: 102;
		overflow: hidden;
		display: none;
		transition: all 0.5s;
		opacity: 0;
		touch-action: none;

		&__bg {
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(#000000, 0.75);
			top: 0;
			left: 0;
			cursor: pointer;
		}

		&--active {
			display: flex;
		}

		&--opacity0 {
			opacity: 0;
		}

		&--opacity1 {
			opacity: 1;
		}

		&__wrap {
			display: block;
			height: 100%;
			width: 46rem;
			position: fixed;
			z-index: 100;
			top: 0;
			left: 0;
			background-color: #ffffff;
			overflow-x: hidden;
			padding: 10rem 5rem 2rem 7.6rem;
			transition: 0.5s;
			opacity: 1;

			@media screen and (max-width: $large_mobile) {
				width: 100%;
				left: -100%;
			}

			&--left0 {
				left: 0px;
			}

			&--left300 {
				left: -480px;

				@media screen and (max-width: $large_mobile) {
					left: -100%;
				}
			}
		}

		&__logo {
			position: absolute;
			top: 1rem;
			left: 3.5rem;

			img {
				max-width: 7.6rem;
				max-height: 7.6rem;
				height: 100%;
				width: 100%;
				object-fit: contain;
				object-position: top;
			}
		}

		&__act {
			position: absolute;
			top: 4.2rem;
			right: 4.2rem;
			max-width: 3.5rem;
			width: 100%;
			object-fit: contain;
			cursor: pointer;
		}

		&__nav {
			position: relative;

			&-list {
				display: flex;
				flex-direction: column;
				list-style: none;
				align-items: flex-start;
			}

			&-item {
				text-align: left;
				font-size: 3.6rem;
				font-weight: 500;
				color: #1d4449;
				line-height: 4.8rem;
				margin-bottom: 6.4rem;

				&:last-of-type {
					margin-bottom: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}

				a {
					display: inline-block;
					position: relative;

					&::before {
						content: '';
						position: absolute;
						left: 0px;
						bottom: -2px;
						width: 100%;
						height: 2px;
						background: #000000;
						transform: scaleX(0);
						transition: transform 0.5s;
					}

					&:hover::before {
						transform: scaleX(1);
					}
				}
			}
		}
	}
}
