@import '../../Styles/varsCupids.scss';

.Cupids {
	font-family: 'Open Sans', sans-serif;

	.team {
		position: relative;
		width: 100%;
		padding: 21.9rem 26rem 14.3rem;

		@media screen and (max-width: $large_desktop) {
			padding: 21.9rem 10rem 14.3rem;
		}

		@media screen and (max-width: $large_tablet) {
			padding: 8rem 0 17.9rem;
		}

		&::before {
			content: '';
			position: absolute;
			top: 5.6rem;
			left: 0;
			height: 100%;
			width: 100%;
			background-size: cover;
			background-position: top;
			background-repeat: no-repeat;
			background-image: url('../../Images/team-bg.png');
			z-index: -1;

			@media screen and (max-width: $large_tablet) {
				height: calc(100% - 14.6rem);
				top: auto;
				bottom: 0;
				background-position: top center;
				background-image: url('../../Images/team-mobile-bg.png');
			}
		}

		&__simple-title {
			text-align: center;
			margin-bottom: 14.2rem;
			color: #000000;

			@media screen and (max-width: $large_tablet) {
				margin-bottom: 0rem;
			}
		}

		&__list {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			@media screen and (max-width: $large_tablet) {
				justify-content: space-between;
			}
		}

		&__item {
			width: calc((100% - 2rem * 2) / 3);
			background: #ffffff;
			box-shadow: 0px 0.4rem 4.2rem rgba(220, 112, 129, 0.1);
			padding: 10.6rem 0rem 11.1rem;
			color: #000000;

			@media screen and (max-width: $large_tablet) {
				max-width: 36.4rem;
				padding: 6.2rem 0;
				width: 100%;
				margin: 0 auto;
			}

			&-title {
				font-weight: bold;
				font-size: 3.2rem;
				line-height: 3.2rem;
				margin-bottom: 0.8rem;
			}

			&-position {
				font-weight: 400;
				font-size: 2rem;
				line-height: 3.2rem;
				color: #dc7081;
				margin-bottom: 2.4rem;
			}

			&-box {
				padding: 0rem 3rem 0rem 10rem;

				@media screen and (max-width: $large_tablet) {
					padding: 0 2rem 0 7.5rem;
				}
			}

			&-info {
				background-color: #ffffff;
				z-index: 2;
				position: relative;

				p {
					font-size: 1.6rem;
					line-height: 2.2rem;
					color: #000000;
				}

				&__wrap {
					display: flex;
					margin-bottom: 1.6rem;
				}

				&__left {
					font-style: normal;
					width: 6.4rem;
					padding-right: 0.5rem;
				}

				&__right {
					font-weight: 600;
				}
			}

			.toggle-content {
				-webkit-transition: 0.5s ease;
				-o-transition: 0.5s ease;
				transition: 1s ease;
				overflow: hidden;
			}

			.toggle-content:not(.show) {
				display: none;
			}
		}

		&__toogle-more {
			display: inline-block;
			cursor: pointer;
			position: relative;
			z-index: 10;
			margin-top: 0.8rem;
			color: #dc7081;
			font-weight: bold;
			font-size: 2rem;
			line-height: 3.2rem;
			border-bottom: 2px dashed #dc7081;
			transition: opacity 0.5s;

			&:hover {
				opacity: 0.7;
			}
		}

		&__img-wrap {
			max-width: 24rem;
			width: 100%;
			margin: 0 auto 2.4rem;

			& > div {
				padding-bottom: 83.33%;
			}

			@media screen and (max-width: $large_tablet) {
				margin: 0 auto 1.6rem;
			}
		}
	}
}
