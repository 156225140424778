@import '../../Styles/varsCupids.scss';

.Cupids {
	.main-screen {
		padding: 22.6rem 37.9rem 15.1rem 26.2rem;
		position: relative;

		@media screen and (max-width: $large_desktop) {
			padding: 22.6rem 20rem 15.1rem 20rem;
		}

		@media screen and (max-width: $large_tablet) {
			padding: 22.8rem 0 0;
			z-index: 1;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: url('../../Images/main-screen-bg.png');
			background-repeat: no-repeat;
			background-size: cover;
			z-index: -2;

			@media screen and (max-width: $large_tablet) {
				background-position: bottom center;
				background-image: url('../../Images/main-screen-mobile-bg.png');
			}
		}

		&__wrap {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;
			z-index: 4;

			@media screen and (max-width: $large_tablet) {
				flex-direction: column-reverse;
			}
		}

		&__right {
			max-width: 57rem;
			width: 100%;

			@media screen and (max-width: $large_desktop) {
				padding-left: 4rem;
			}

			@media screen and (max-width: $large_mobile) {
				max-width: 100%;
				padding: 0 15px;
				margin-bottom: 1.5rem;
			}
		}

		&__img-wrap {
			max-width: 57.2rem;
			width: 100%;
		}

		&__title {
			color: #ad4656;
			margin-bottom: 1.6rem;

			@media screen and (max-width: $large_tablet) {
				text-align: center;
			}
		}

		&__content {
			width: 100%;
		}
	}
}
