@import '../../Styles/vars.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.SadBear {
	.header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 100;

		.header-anim {
			padding: 4rem 0 0;
			opacity: 0;
			animation: header-opacity 1s;
			animation-fill-mode: forwards !important;
		}

		&--fixed {
			.header-anim {
				padding: 2rem 0;
				opacity: 1;
				background: #ffffff;
				box-shadow: 0px 4px 50px #e1e0e0;
				transform: translate(0, -100%);
				animation: header-height 0.5s !important;
				animation-fill-mode: forwards !important;
			}
		}

		&--height0 {
			.header-anim {
				transform: translate(0, 0);
				animation: header-height0 0.5s !important;
			}
		}

		@keyframes header-height {
			100% {
				transform: translate(0, 0);
			}
		}

		@keyframes header-height0 {
			100% {
				transform: translate(0, -100%);
			}
		}

		@keyframes header-opacity {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		&__wrap {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;

			@media screen and (max-width: $large_tablet) {
			}
		}

		&__logo {
			font-size: 2rem;
			line-height: 2.4rem;
			color: #121212;
			font-weight: normal;
			display: flex;
			align-items: center;

			p {
				font-family: 'Graphik';
				margin-left: 1.2rem;
				position: relative;
				font-size: 2.4rem;
				line-height: 2.6rem;

				span {
					font-weight: 900;
					color: #121212;
					margin-right: 0.4rem;
				}

				&::before {
					position: absolute;
					content: '';
					top: 100%;
					height: 1px;
					width: 100%;
					background-color: #121212;
					opacity: 0;
					transition: 0.5s;
				}
			}

			&:hover {
				p::before {
					opacity: 1;
				}
			}
		}

		&__nav {
			&-list {
				display: flex;
				align-items: center;
			}

			&-item {
				margin-right: 2.4rem;

				&:last-child {
					margin-right: 0px;
				}
			}

			&-link {
				position: relative;
				font-weight: 500;
				font-size: 2rem;
				line-height: 3rem;
				color: #000000;
				font-family: 'Laila', sans-serif;

				&::before {
					content: '';
					position: absolute;
					left: 0px;
					bottom: -2px;
					width: 100%;
					height: 1px;
					background: #000000;
					transform: scaleX(0);
					transition: transform 0.5s;
				}

				&:hover::before {
					transform: scaleX(1);
				}
			}
		}

		&__socials {
			@media screen and (max-width: $large_tablet) {
				margin-left: auto;
				margin-right: 2.8rem;
			}
		}

		&__burger {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 1.6rem;
			cursor: pointer;

			&:hover div {
				&:nth-child(2) {
					width: 50%;
				}
			}

			div {
				margin-bottom: 0.4rem;
				width: 100%;
				border-bottom: 2px solid #7eb3bb;
				transition: width 0.5s, border-bottom-color 0.5s;

				&:nth-child(2) {
					width: 100%;
				}

				&:last-child {
					margin-bottom: 0px;
				}
			}
		}
	}
}
