@import '../../Styles/varsJacks.scss';

.Jacks {
	.main-screen {
		background-color: #140c2f;
		padding-top: 21.5rem;
		padding-bottom: 20rem;
		position: relative;

		@media screen and (max-width: $large_tablet) {
			padding-top: 11rem;
		}

		@media screen and (max-width: $large_mobile) {
			padding-bottom: 23.5rem;
		}

		.center-wrap {
			@media screen and (max-width: $large_mobile) {
				max-width: 100%;
				padding: 0;
			}
		}

		&__bg {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 120%;
			object-fit: cover;
		}

		&__wrap {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;
			z-index: 4;

			@media screen and (max-width: $large_tablet) {
				flex-direction: column;
			}
		}

		&__left {
			max-width: 55rem;
			width: 100%;

			@media screen and (max-width: $large_mobile) {
				max-width: 100%;
				padding: 0 15px;
				margin-bottom: 1.5rem;
			}
		}

		&__img-wrap {
			max-width: 69rem;
		}

		&__title {
			font-weight: bold;
			font-size: 8rem;
			line-height: 88/80 * 1;
			text-transform: uppercase;
			color: $secondary-c;
			margin-bottom: 2.5rem;

			@media screen and (max-width: $large_tablet) {
				font-size: 6.4rem;
			}
		}

		&__content {
			font-weight: 300;
			font-size: 2rem;
			line-height: 140%;
			width: 100%;
			margin-bottom: 4rem;

			@media screen and (max-width: $large_tablet) {
				margin-bottom: 4.5rem;
			}
		}

		&__btn {
			margin-bottom: 4.5rem;
		}

		&__value {
			font-weight: 600;
			font-size: 3.6rem;
			line-height: 40/36 * 1;
			letter-spacing: 0.04em;
			margin-bottom: 2.5rem;

			span {
				color: $secondary-c;
			}
		}

		&__availabl {
			font-size: 2.4rem;
			line-height: 26/24 * 1;
			letter-spacing: 0.04em;
		}
	}
}
