@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oleo+Script&display=swap');
@import '../../Styles/varsCupids.scss';

.Cupids {
	font-size: 100vw / (1920/10);

	@media screen and (max-width: $large_desktop) {
		font-size: 100vw / (1480/10);
	}

	@media screen and (max-width: $large_tablet) {
		font-size: 80.5%;
	}

	@media screen and (max-width: $large_mobile) {
		font-size: 100vw / (480 /10);
	}

	.desktop--hide {
		@media screen and (min-width: $large_tablet) {
			display: none !important;
		}
	}

	.mobile--hide {
		@media screen and (max-width: $large_tablet) {
			display: none !important;
		}
	}

	.mobile-opacity-top,
	.mobile-opacity-bottom {
		content: '';
		position: absolute;
		width: 100%;
		height: 15rem;
		left: 0;
		background: rgb(255, 255, 255);
		z-index: -1;
	}
	.mobile-opacity-top {
		top: 0;
		background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
	}
	.mobile-opacity-bottom {
		bottom: 0;
		background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	body {
		background-color: #ffffff;
	}

	.center-wrap {
		max-width: 100%;
		width: 100%;
		padding: 0;

		@media screen and (max-width: $large_tablet) {
			max-width: 600px;
			padding: 0 32px;
		}
	}

	.simple-title {
		font-family: 'Oleo Script', cursive;
		font-weight: normal;
		font-size: 9.6rem;
		line-height: 13.3rem;

		@media screen and (max-width: $large_tablet) {
			font-size: 4.8rem;
			line-height: 6.6rem;
		}
	}

	.content {
		p {
			font-weight: normal;
			font-size: 2rem;
			line-height: 3.2rem;
			width: 100%;
			color: #000000;

			@media screen and (max-width: $large_tablet) {
				font-size: 1.6rem;
				line-height: 2.4rem;
			}

			a {
				color: #dc7081;
			}

			& + p {
				margin-top: 2rem;
			}
		}
	}

	.socials {
		display: flex;

		&__link {
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.5s;
			width: 2.8rem;
			height: 2.8rem;

			svg {
				width: 100%;
				height: 100%;
				transition: all 0.5s;
				will-change: transform;

				&:hover {
					transform: scale(1.1);
				}
			}

			& + .socials__link {
				margin: 0 0 0 2rem;

				@media screen and (max-width: $large_tablet) {
					margin-left: 1.6rem;
				}
			}
		}
	}
}
