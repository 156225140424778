@import '../../Styles/varsJacks.scss';

.Jacks {
	.mobile-menu {
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 0px;
		bottom: 0px;
		z-index: 102;
		overflow: hidden;
		display: none;
		transition: all 0.5s;
		opacity: 0;
		touch-action: none;

		&__bg {
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(#0e0726, 0.75);
			top: 0;
			left: 0;
			cursor: pointer;
		}

		&--active {
			display: flex;
		}

		&--opacity0 {
			opacity: 0;
		}

		&--opacity1 {
			opacity: 1;
		}

		&__wrap {
			background-image: url('../../Images/mobile-bg.png');
			background-repeat: no-repeat;
			background-size: cover;
			padding: 3rem 15px;
			width: 480px;
			height: 100%;
			position: absolute;
			left: -480px;
			transition: left 0.5s;
			display: flex;
			flex-direction: column;

			@media screen and (max-width: $large_mobile) {
				width: 100%;
				left: -100%;
			}

			&--left0 {
				left: 0px;
			}

			&--left300 {
				left: -480px;

				@media screen and (max-width: $large_mobile) {
					left: -100%;
				}
			}
		}

		&__top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 7rem;
		}

		&__logo {
			max-width: 19.5rem;

			> div {
				padding-bottom: 28.2%;

				img {
					object-fit: contain;
				}
			}
		}

		&__act {
			width: 2rem;
			height: 2rem;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}

		&__nav {
			position: relative;

			&-list {
				display: flex;
				flex-direction: column;
				list-style: none;
				align-items: center;
			}

			&-item {
				margin-bottom: 4rem;
				position: relative;

				&::before {
					content: '';
					position: absolute;
					left: -10%;
					bottom: 3px;
					width: 120%;
					height: 1rem;
					background: #e87741;
					transform: scaleX(0);
					transition: transform 0.5s;
					will-change: transform;
				}

				&:hover::before {
					transform: scaleX(1);
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			&-link {
				font-size: 3.6rem;
				position: relative;
				z-index: 5;
			}
		}
	}
}
