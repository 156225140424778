@font-face {
	font-family: 'Graphik';
	src: url('../assets/fonts/Graphik/Graphik-Light.woff2');
	font-weight: 300;
	font-display: swap;
}

@font-face {
	font-family: 'Hiruko';
	src: url('../assets/fonts/HirukoBlackAlternate/HirukoBlackAlternate.woff');
	font-weight: 400;
}

@font-face {
	font-family: 'Graphik';
	src: url('../assets/fonts/Graphik/Graphik-Regular.woff2');
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'Graphik';
	src: url('../assets/fonts/Graphik/Graphik-Semibold.woff2');
	font-weight: 600;
	font-display: swap;
}

@font-face {
	font-family: 'Graphik';
	src: url('../assets/fonts/Graphik/Graphik-Bold.woff2');
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: 'OpenSans';
	src: url('../assets/fonts/OpenSans/OpenSans-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: 'OpenSans';
	src: url('../assets/fonts/OpenSans/OpenSans-Regular.ttf');
	font-weight: 400;
}
@font-face {
	font-family: 'OpenSans';
	src: url('../assets/fonts/OpenSans/OpenSans-Semibold.ttf');
	font-weight: 600;
}
