@import '../../Styles/vars.scss';
@import url('https://fonts.googleapis.com/css2?family=Laila:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.SadBear {
	font-size: 100vw / (1920/10);

	@media screen and (max-width: $large_tablet) {
		font-size: 80.5%;
	}

	@media screen and (max-width: $large_mobile) {
		font-size: 100vw / (480 /10);
	}

	body {
		font-family: 'Roboto', sans-serif;

		&::-webkit-scrollbar {
			width: 0;
		}
	}

	.center-wrap {
		width: 100%;
		max-width: 143rem;
		margin: 0px auto;
		padding: 0px 1.5rem;

		@media screen and (max-width: $large_tablet) {
			max-width: $large_mobile;
			padding: 0 3.2rem;
		}
	}

	.simple-title {
		font-family: 'Laila', sans-serif;
		font-weight: bold;
		font-size: 8rem;
		line-height: 7.2rem;
		color: #1d4449;
		text-align: center;

		@media screen and (max-width: $large_tablet) {
			font-size: 4rem;
			line-height: 7.2rem;
		}
	}

	.content {
		a {
			color: #00ffff;
		}
	}

	.small-title {
		color: #fff;
	}

	.sad-bear {
		overflow: hidden;
	}

	.fog-bg {
		position: relative;
		z-index: 1;
		width: 100%;

		&::before {
			content: '';
			width: 100%;
			height: calc(100% - 11.7rem);
			background-image: url('../../Images/fog-bg.png');
			position: absolute;
			top: 11.7rem;
			left: 0;
			background-position: top center;
			background-repeat: no-repeat;
			background-size: cover;
			z-index: 1;
		}
	}

	.desktop--hide {
		@media screen and (min-width: $large_tablet) {
			display: none !important;
		}
	}

	.mobile--hide {
		@media screen and (max-width: $large_tablet) {
			display: none !important;
		}
	}
}
