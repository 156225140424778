@import '../../Styles/vars.scss';
.SadBear {
	.faq {
		position: relative;
		overflow: hidden;
		z-index: 2;
		background-color: #fff;
		padding-bottom: 19.4rem;

		@media screen and (max-width: $large_tablet) {
			padding: 7.4rem 0 57rem;
		}

		&::before {
			content: '';
			position: absolute;
			background: linear-gradient(
				180deg,
				#ffffff 0%,
				rgba(255, 255, 255, 0) 100%
			);
			top: 0;
			width: 100%;
			height: 15rem;
			z-index: -1;

			@media screen and (max-width: $large_tablet) {
				height: 40rem;
			}
		}

		&__wrap {
			padding: 0 12rem;

			@media screen and (max-width: $large_tablet) {
				padding: 0;
			}
		}

		&__bg {
			position: absolute;
			z-index: -2;
			bottom: 0;
			width: 100%;

			@media screen and (max-width: $large_tablet) {
				object-fit: cover;
				object-position: 38% bottom;
				height: 150%;
			}
		}

		&__title {
			margin-bottom: 8rem;

			@media screen and (max-width: $large_tablet) {
				margin-bottom: 4rem;
			}
		}

		&__question {
			border-bottom: 1px solid #1d4449;
			padding: 3.2rem 0;

			@media screen and (max-width: $large_tablet) {
				padding: 2.4rem 0;
			}

			&:last-of-type {
				border-bottom: none;
			}

			&-title {
				font-family: 'Laila', sans-serif;
				font-weight: bold;
				font-size: 4rem;
				line-height: 6.4rem;
				color: #1d4449;
				position: relative;
				cursor: pointer;

				@media screen and (max-width: $large_tablet) {
					font-size: 2rem;
					line-height: 3.6rem;
					padding-right: 8rem;
				}

				&::before {
					content: '';
					position: absolute;
					right: 0;
					width: 4.8rem;
					height: 4.8rem;
					background-image: url('../../Images/faq-title-arrow.svg');
					background-repeat: no-repeat;
					background-size: contain;
					transform: translateY(-50%);
					top: 50%;
					transition: 1s;
				}
			}

			&--active {
				.faq__question-title::before {
					transform: rotate(90deg) translateX(-50%);
				}
			}
		}

		&__answer-content {
			max-height: 0px;
			overflow: hidden;
			transition: max-height 1s;
		}

		&__answer {
			padding-top: 3.2rem;
			font-size: 2.4rem;
			line-height: 3.6rem;
			color: #121212;

			@media screen and (max-width: $large_tablet) {
				padding-top: 3.8rem;
				font-size: 1.6rem;
				line-height: 2.4rem;
			}
		}

		ul {
			font-size: 2.4rem;
			line-height: 3.6rem;
			color: #121212;

			@media screen and (max-width: $large_tablet) {
				font-size: 1.6rem;
				line-height: 2.4rem;
			}

			li {
				position: relative;
				padding-left: 3.5rem;

				&::before {
					content: '';
					position: absolute;
					width: 0.5rem;
					height: 0.5rem;
					border-radius: 50%;
					top: 1.3rem;
					left: 1.5rem;
					background-color: #000000;

					@media screen and (max-width: $large_tablet) {
						width: 0.4rem;
						height: 0.4rem;
						top: 0.9rem;
						left: 1.85rem;
					}
				}
			}
		}
	}
}
