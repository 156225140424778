@import '../../Styles/varsJacks.scss';

.Jacks {
	.rarity {
		padding: 14rem 0;
		position: relative;
		background-color: #140c2f;

		@media screen and (max-width: $large_tablet) {
			padding: 11rem 0 8rem;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #140c2f;
			z-index: -1;
		}

		.center-wrap {
			@media screen and (max-width: $large_mobile) {
				padding: 0;
				max-width: 100%;
			}
		}

		&__wrap {
			display: flex;
			align-items: center;
			max-width: 125rem;
			width: 100%;
			margin: 0 auto;

			@media screen and (max-width: $large_tablet) {
				flex-direction: column;
			}
		}

		&__left {
			max-width: 45rem;
			width: 100%;

			@media screen and (max-width: $large_tablet) {
				max-width: 100%;
				margin-bottom: 1rem;
			}

			@media screen and (max-width: $large_mobile) {
				padding: 0 15px;
			}
		}

		&__right {
			width: calc(100% - 45rem - 1.5rem);
			margin-left: 1.5rem;

			@media screen and (max-width: $large_tablet) {
				width: 100%;
				margin-left: 0;
			}

			> div {
				padding-bottom: 64.45%;

				@media screen and (max-width: $large_mobile) {
					padding-bottom: 74.45%;
				}
			}
		}

		&__small-title {
			margin-bottom: 1.5rem;
		}

		&__simple-title {
			margin-bottom: 6.5rem;
		}
	}
}
