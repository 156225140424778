@import '../../Styles/vars.scss';

.SadBear {
	.road-map {
		position: relative;
		z-index: 1;

		&__wrap {
			position: relative;
			padding: 8.4rem 0rem 16.1rem;

			@media screen and (max-width: $large_tablet) {
				padding: 5.7rem 0rem 7.3rem;
			}
		}

		&__title {
			margin-bottom: 7.5rem;

			@media screen and (max-width: $large_tablet) {
				margin-bottom: 3.6rem;
			}
		}

		&__text-bottom {
			font-family: 'Laila', sans-serif;
			font-weight: bold;
			color: #ffffff;
			font-size: 4.8rem;
			line-height: 7.2rem;
			max-width: 53.5rem;
			width: 100%;
			position: absolute;
			bottom: 27.3rem;
			left: 0;

			@media screen and (max-width: $large_tablet) {
				display: none;
			}
		}

		&__img {
			max-width: 119.5rem;
			width: 100%;
			display: block;
			margin: 0 0 5.1rem 9.5rem;

			@media screen and (max-width: $large_tablet) {
				display: none;
			}
		}

		&__minting {
			font-family: 'Laila', sans-serif;
			font-weight: bold;
			font-size: 6.4rem;
			line-height: 7.2rem;
			color: #3eac94;
			text-align: center;
			margin-bottom: 17.6rem;

			@media screen and (max-width: $large_tablet) {
				font-size: 3.6rem;
				line-height: 5.4rem;
				margin-bottom: 10.4rem;
			}
		}

		&__list {
			display: flex;
			justify-content: space-between;

			@media screen and (max-width: $large_tablet) {
				flex-direction: column;
				align-items: flex-end;
				padding-right: 2rem;
			}
		}

		&__item {
			width: calc(100% / 4);
			padding-right: 2.4rem;
			padding-left: 3rem;
			position: relative;

			&::after,
			&::before {
				content: '';
				position: absolute;
				background-size: contain;
				background-repeat: no-repeat;
				display: none;

				@media screen and (max-width: $large_tablet) {
					display: block;
				}
			}

			&::before {
				top: 0;
				background-image: url('../../Images/road-map__item.svg');
				width: 4.3rem;
				height: 4.84rem;
				left: 2rem;
			}

			&::after {
				width: 4rem;
				height: 100%;
				top: 6rem;
				left: 6rem;
				background-size: cover;
			}

			&:nth-of-type(1) {
				&::after {
					background-image: url('../../Images/road-map__item-dash1.svg');
				}
			}
			&:nth-of-type(2) {
				&::after {
					background-image: url('../../Images/road-map__item-dash2.svg');
					left: -1rem;
					top: 8rem;
				}
				&::before {
					transform: rotate(70deg);
				}
			}
			&:nth-of-type(3) {
				&::after {
					top: 7rem;
					background-image: url('../../Images/road-map__item-dash3.svg');
					left: 5rem;
				}
			}
			&:nth-of-type(4) {
				&::before {
					transform: rotate(70deg);
				}
			}

			@media screen and (max-width: $large_tablet) {
				width: 100%;
				padding: 0;
				margin-bottom: 8rem;
			}

			.content {
				@media screen and (max-width: $large_tablet) {
					width: 100%;
					max-width: 26.7rem;
					padding-left: 2.5rem;
					margin-left: auto;

					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}

			&-rhase {
				position: absolute;
				left: 0;
				top: 0;
			}

			&:last-of-type {
				padding-right: 0;
			}

			h3 {
				font-family: 'Laila', sans-serif;
				font-weight: bold;
				font-size: 4.8rem;
				line-height: 6.4rem;
				margin-bottom: 2.4rem;
				color: #1d4449;

				@media screen and (max-width: $large_tablet) {
					font-size: 3.2rem;
					line-height: 4.8rem;
					margin-bottom: 1.6rem;
				}
			}

			h4 {
				font-weight: 500;
				font-size: 2.4rem;
				line-height: 3.2rem;
				color: #000000;
				margin-bottom: 2.4rem;

				@media screen and (max-width: $large_tablet) {
					font-size: 1.6rem;
					line-height: 2.4rem;
				}
			}

			.h4-none {
				height: 3.1rem;
				margin-bottom: 2.4rem;

				@media screen and (max-width: $large_tablet) {
					display: none;
				}
			}

			ul {
				li {
					font-size: 2.4rem;
					line-height: 3.2rem;
					margin-bottom: 2.4rem;
					color: #000000;
					position: relative;

					@media screen and (max-width: $large_tablet) {
						font-size: 1.6rem;
						line-height: 2.4rem;
					}

					&::before {
						content: '';
						position: absolute;
						width: 0.5rem;
						height: 0.5rem;
						border-radius: 50%;
						top: 1.1rem;
						left: -2rem;
						background-color: #000000;

						@media screen and (max-width: $large_tablet) {
							top: 0.8rem;
							width: 0.4rem;
							height: 0.4rem;
							left: -1.2rem;
						}
					}

					ul {
						padding-top: 1.2rem;

						li {
							padding-left: 2.8rem;
							font-size: 2rem;
							line-height: 2.8rem;

							&::before {
								width: 1.2rem;
								height: 0.2rem;
								border-radius: 0;
								left: 0.4rem;
							}
						}
					}

					a {
						color: #000000;
						text-decoration: underline;
					}

					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
