@import '../Styles/varsJacks.scss';

.Jacks {
	font-size: 62.5%;

	@media screen and (max-width: $large_desktop) {
		font-size: 100vw / (1480/10);
	}

	@media screen and (max-width: $large_tablet) {
		font-size: 80.5%;
	}

	@media screen and (max-width: $large_mobile) {
		font-size: 100vw / (480 /10);
	}

	body {
		background-color: #140c2f;
		font-size: 1.6rem;
		font-family: 'Graphik', sans-serif;
	}

	.center-wrap {
		max-width: $wrap;
		width: 100%;
		margin: 0px auto;
		padding: 0px 15px;

		@media screen and (max-width: $large_desktop) {
			max-width: calc(100% - 50px);
		}

		@media screen and (max-width: $large_tablet) {
			max-width: $large_mobile;
		}
	}

	.small-title {
		color: #ff9460;
		font-weight: 300;
		font-size: 2.4rem;
		line-height: 26/24 * 1;
		letter-spacing: 0.08em;
	}

	.simple-title {
		font-weight: bold;
		font-size: 4.8rem;
		line-height: 53/48 * 1;

		@media screen and (max-width: $large_tablet) {
		}
	}

	.desktop--hide {
		@media screen and (min-width: $large_tablet) {
			display: none !important;
		}
	}

	.mobile--hide {
		@media screen and (max-width: $large_tablet) {
			display: none !important;
		}
	}
}
